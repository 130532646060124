import { RegistrationContent, classListRegistrationState, newClassListRegData, newClassListRegistrations } from "../common/types";
import { CreateAllTimesClassListsV2 } from "../functions/ClassListsFeatures/ClassListSortingFunctions/InitialSortClassListsV2/CreateAllTimesClassLists";


export const CreateCamperValues = (classListContent:classListRegistrationState, registrationContent:RegistrationContent[], isComboCamp:boolean) => {
    let totalCountAM = 0;
    let totalCountPM = 0;
    let allClassesByTime: classListRegistrationState = CreateAllTimesClassListsV2(registrationContent)

    // Function to check if any student has the specified skill levels for bike tots classes
    const hasSkillLevel = (classes: any[], skillLevelsToCheck:number[] ) => {
        return classes.some((student) => skillLevelsToCheck.includes(student['skillLevel']['id']));
    };

    // Function to count swim classes by time block
    const countClassByTime = (classes: any[]) =>{
        const startTimeCounts:string[] = [];
        classes.forEach((sublist:any[]) => {
            startTimeCounts.push(sublist[0].categoryTime.startTime);
        });
        const counts:any = {};
        let maxCount:number = 0;
       startTimeCounts.forEach(item => {
            if (counts[item]) {
                counts[item]++;
            } else {
                counts[item] = 1;
            }
            if (counts[item] > maxCount) {
                maxCount = counts[item];
            }
        });
        return maxCount;
    }

    const halfClass = [1, 2, 3102, 2021, 2134]
    allClassesByTime && Object.entries(allClassesByTime).forEach((classByTime: [string, any]) => {
        if (classByTime[0] === "am") {
            Object.entries(classByTime[1]).forEach((classObj:[string, any]) => {
                if(classObj[0].includes("Swim")){
                    if (countClassByTime(classObj[1]['classLists'])){
                        totalCountAM += countClassByTime(classObj[1]['classLists'])
                    }
                }else{
                    Object.values(classObj[1]['classLists']).forEach((classes: any) => {
                        if (hasSkillLevel(classes, halfClass)){
                            totalCountAM += 0.5
                        }else{
                            totalCountAM += 1
                        }
                    })
                }
            })
        }
        if (classByTime[0] === "pm") {
            Object.entries(classByTime[1]).forEach((classObj:[string, any]) => {
                if(classObj[0].includes("Swim")){
                    if (countClassByTime(classObj[1]['classLists'])){
                        totalCountPM += countClassByTime(classObj[1]['classLists'])
                    }
                }else{
                    Object.values(classObj[1]['classLists']).forEach((classes: any) => {
                        if (hasSkillLevel(classes, halfClass)){
                            totalCountPM += 0.5
                        }else{
                            totalCountPM += 1
                        }
                    })
                }
            })
        }
    })
    // Get Camper Numbers
    let pmNumbers = 0;
    let amNumbers = 0;
    let adNumbers = 0;
    let amInstructorNumbers = Math.ceil(totalCountAM);
    let pmInstructorNumbers = Math.ceil(totalCountPM);
    let allNumbers = 0;

    // count am and pm active kids
    Object.entries(classListContent).forEach(([timeBlock, classList]: [string, newClassListRegistrations]) => {
  
        Object.entries(classList).forEach(([programType, classes]: [string,  newClassListRegData]) => {
            if (timeBlock === "am") {
                if(classes.registrations){
                    amNumbers += classes.registrations.length
                }
            } else if (timeBlock === "pm") {
                if(classes.registrations){
                    pmNumbers += classes.registrations.length
                }
            }
     })
    })
    // count active all day numbers from regs
    const incrementAdNumbers = () => {
        adNumbers += 1;
    };

    for (let registration of registrationContent) {
        if (!isComboCamp) {
            if (registration['categoryTime']['endTime'] > '14:00' && registration['categoryTime']['startTime'] < '11:00') {
                registration['registrations'].forEach(incrementAdNumbers);
            }
        } else {
            adNumbers = 0;
        }
    }

    allNumbers = (amNumbers + pmNumbers) - adNumbers;

    return {amNumbers, pmNumbers, adNumbers, amInstructorNumbers, pmInstructorNumbers, allNumbers}
  
}