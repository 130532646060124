import { RegistrationContent, classListRegistrationState } from "../../common/types";
import { CreateCamperValues } from "../../functions/CreateCamperValues";

export const CamperValues = (classListContent:classListRegistrationState, registrationContent:RegistrationContent[], isComboCamp:boolean) => {
    
    const campValues = CreateCamperValues(classListContent, registrationContent, isComboCamp);

    return (
        <div>
            <div className="camp-details-block">
                <div>
                    <p><strong>Campers(AM):</strong> {campValues.amNumbers}</p>

                    <p><strong>Instructors(AM):</strong> {campValues.amInstructorNumbers}</p>
                </div>
                <div>
                    <p><strong>Campers(PM):</strong> {campValues.pmNumbers}</p>

                    <p><strong>Instructors(PM):</strong> {campValues.pmInstructorNumbers}</p>
                </div>
                <div>
                    <p><strong>Total # of Campers:</strong> {campValues.allNumbers}</p>
                </div>
            </div>
        </div>
    )
}